import { handleMessages } from './handle-messages';

export function startUsernameSavedMessage() {
  document.addEventListener("DOMContentLoaded", function () {
    handleMessages(
      "username-saved-message",
      "dismiss-username-saved-message",
      "usernameSavedMessageDismissed"
    );
  });
}
